import React from 'react'
import '../CSS/Components/Skills.css';

import { Link } from 'react-router-dom';


function Skills() {
    return (
        <div className='skills-container'>
        
        <h1 className='skills-container'>SKILLS</h1>
       
    
        <div className='skills-type'>
          <div className='skills-type-wrapper'>
            <div className='skills-type-items'>
              <h2 className='skills-type-items' >Front-end</h2>
              <Link className='skills-type-link' to='/projects'>React</Link>
              <Link className='skills-type-link' to='/projects'>React Native</Link>
              <Link className='skills-type-link' to='/projects'>JavaScript</Link>
              <Link className='skills-type-link' to='/projects'>CSS/SCSS</Link>
              <Link className='skills-type-link' to='/projects'>HTML</Link>
              <Link className='skills-type-link' to='/projects'>jQuery</Link>
              <Link className='skills-type-link' to='/projects'>Git/Version Control</Link>
            
            </div>
           
            <div className='skills-type-items'>
              <h2 className='skills-type-items' >Back-end </h2>
              <Link className='skills-type-link' to='/'>Java</Link>
              <Link className='skills-type-link' to='/'>Python</Link>
              <Link className='skills-type-link' to='/'>TypeScript</Link>
              <Link className='skills-type-link' to='/'>SQL/MySQL</Link>
              <Link className='skills-type-link' to='/'>C/C++</Link>
              <Link className='skills-type-link' to='/'>Node.js</Link>
              <Link className='skills-type-link' to='/'>R programming</Link>
            </div>
            
         
         
            <div className='skills-type-items'>
              <h2 className='skills-type-items' >Others</h2>
              <Link className='skills-type-link' to='/'>Process</Link>
              <Link className='skills-type-link' to='/'>Manager</Link>
              <Link className='skills-type-link' to='/'>Kanban</Link>
              <Link className='skills-type-link' to='/'>Maths</Link>
              <Link className='skills-type-link' to='/'>Planning/Organisational</Link>
              <Link className='skills-type-link' to='/'>Problem-solving</Link>
              <Link className='skills-type-link' to='/'>Web/Mobile</Link>
            </div>
            </div>
        </div>
        
      </div>
    )
}

export default Skills
