import "../App.css";

import "../CSS/Pages/CvInfor.css";

function CvInfor() {
  return (
    <div className="infor-container">
      <h1 className="infor-container" >Derek Bomfim Prates</h1>

      <p  className="infor-container" >
        I am an experienced and analytical bilingual professional, English and
        Portuguese, with a BSc Degree in Industrial Engineering and Higher
        Diploma in Science in Computing with experience in developing and
        implementing action plans to improve services using methods as
        continuous improvement, mathematical programming, production costs,
        labour engineering. I have knowledge in programming in Back-end and
        Front-end in mobile and web development. Also, I have experience in
        managing teams and planning solutions to improve the work environment.</p>
        <p  className="infor-container" >
        My experience with my first graduation course provided me a
        closer contact with different science’s areas, such as: IT, biology,
        physics, chemistry and math. This knowledge enabled the development of
        different mathematical algorithms for academic works from an
        interdisciplinary perspective.</p>
        <p  className="infor-container" >
        I worked at INSS – Social Security
        National Institute – developing and implementing action plans to improve
        services by using methods learnt during my industrial engineering
        graduation course. Some of these technics consists of: continuous
        improvement, mathematical programming, production costs, labor
        engineering. </p>
        <p  className="infor-container" >
        Furthermore, I founded by my own initiative, during my
        industrial engineering graduation course, a junior company called:
        Melius company. This company focus on providing the first professional
        contact of engineering students with the labour market. I consider
        myself a hardworking person, focused on my professional and personal
        purposes, dedicated, proactive, easy to work under pression and in
        teams.
      </p>

      <h3  className="infor-container" >HIGHER EDUCATION:</h3>

      <h4  className="infor-container" >CCT COLLEGE - DUBLIN, IRELAND | 2020-2021 (NFQ LEVEL 8)</h4>

      <p  className="infor-container" >
      COLLATION DEGREE: November, 2021, HIGHER DIPLOMA IN SCIENCE IN COMPUTING
      </p>

      <h4 className="infor-container" >
        THE FEDERAL UNIVERSITY OF JEQUITINHONHA AND MUCURI VALLEYS (BRAZIL) | 2012-2017 (NFQ Level 8)
      </h4>

      <p className="infor-container" >COLLATION DEGREE: August, 2017, BACHELOR IN INDUSTRIAL ENGINEERING</p>
      <p className="infor-container" >COLLATION DEGREE: August, 2015, BACHELOR IN SCIENCE AND TECHNOLOGY</p>

      <h3 className="infor-container" >PROFESSIONAL EXPERIENCE:</h3>

      <h4 className="infor-container" >Store Manager at Scoop Dessert Parlour | 2019 - Present</h4>

      <h4 className="infor-container" >Researcher at UFVJM and at LNCC - National Laboratory of Scientific
        Computation| 2012-2017 </h4>

        <p className="infor-container" > I worked as a CNPq researcher on the study, supported by
        CAPES, about several epidemiological mathematical models, implementing
        and applicating them in real epidemiological phenomena.</p>
      <h4 className="infor-container" >INSS - Social Security National Institute (Government Agency) | 2015 -2017 </h4>
      <p className="infor-container" >
        I worked at SEAT - the section of the executive management of the city Teófilo
        Otoni – in the State of Minas Gerais. I developed studies and I made the
        management of performance indicators of the units belonging to the
        management, totalizing 13 agencies of social security (APS). I created
        actions plans to propose improvements to the customer service by using
        mathematical algorithms and concepts that cover labor engineering,
        production costs, marketing, planning and controlling production. This
        work obtained remarkable results, reducing the waiting time from 65 days
        to only 10 days.
      </p>
        <h4 className="infor-container" >Melius EJ | 2016-2017  </h4>
      <p className="infor-container" >
        I dealt with all bureaucratic part to found the company. I
        organized the strategic planning, I revised, I defined and I controlled
        the strategic indicators of the company. I elaborated strategies of
        marketing, analyzing regions where the companies have the opportunity to
        develop, to grow. In addition, I created communication measures to the
        improvement of the companies with the same commercial profile.
      </p>

      <h3 className="infor-container" >SKILLS:</h3>

      <p className="infor-container" >Programs: Trello, Microsoft Word, Excel and PowerPoint.</p>
      
      <p className="infor-container" >
        Knowledge of programming languages such as: Java, JavaScript, statistical software
        R, C and C++.
      </p>
      <p className="infor-container" >
        Knowledge of research: production, applications and analysis results.
      </p>
      <p className="infor-container" >Experience in team coordination and administrative routines.</p>

      <h3 className="infor-container" >LANGUAGES:</h3>

      <p className="infor-container" >Portuguese: Native Language </p>
      <p className="infor-container" >English: Fluent (Well spoken, written and Reading)</p>
    </div>
  );
}

export default CvInfor;
