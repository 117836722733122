import "../App.css";

import "../CSS/Pages/ProjectsInfo.css";

function ProjectsInfo() {
  return (
    <div className="main-container">
      <div className="projects-container">
        <h1 className="projects-container">
          Below I show some projects that I developed during and after my
          graduation in IT. The projects were developed in React, React Native,
          JavaScript, CSS, HTML and PHP.{" "}
        </h1>
      </div>
      <div className="cards">
        <div className="card card-1">
          <div className="card_icon">
            <i className="fab fa-github"></i>
          </div>
          <p className="card_exit">
            <i className="fas fa-times"></i>
          </p>
          <h2 className="card_content">
            {" "}
            The application was developed in React Native and JavaScript and it
            has the following functions: Register users in database (SQLite),
            Authenticate users, find users by id, update/delete user. The
            application was designed to be user-friendly, with easy access
            buttons and fewer steps to register users. On the home screen, it is
            possible to access all functions the application offers to users .
            Thus, it helps the user go through all functions that the app
            provides with fewer steps quickly.
          </h2>
          <p className="card_apply">
            <a
              className="card_link"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/derekbomfimprates/SQLiteProject"
            >
              See code <i className="fas fa-arrow-right"></i>
            </a>
          </p>
        </div>
        <div className="card card-2">
          <div className="card_icon">
            <i className="fab fa-github"></i>
          </div>
          <p className="card_exit">
            <i className="fas fa-times"></i>
          </p>
          <h2 className="card_content">
            This application was created to support the users during a
            collective trip to split the amount spent. The system has a Java
            backend and a REACT Native mobile application front end. The
            application allows people to create a label, name of the trip, and
            store information referring to the trip, that is, all the expenses
            that each person had during the trip. It is possible to consult all
            costs at any time of the trip and close the trip. At the end of the
            trip, the application presents a report with all the information
            about the trip and the amount that must be paid to each member.
            Then, in the end, all expenses will be divided equally among the
            members of the trip.
          </h2>
          <p className="card_apply">
            <a
              className="card_link"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/derekbomfimprates/SplitEasily"
            >
              See code <i className="fas fa-arrow-right"></i>
            </a>
          </p>
        </div>
        <div className="card card-3">
          <div className="card_icon">
            <i className="fab fa-github"></i>
          </div>
          <p className="card_exit">
            <i className="fas fa-times"></i>
          </p>
          <h2 className="card_content">
            The "Ger Garage "Mobile Application was designed based on the need
            to implement a system to organise and manage booking for a small
            garage, the "Ger Garage" Mobile Application was designed. This
            application solves a real problem for small businesses regarding
            booking allocation while helping to improve customer service,
            maximising repair time, and streamlining work. "Ger Garagen" offers
            a mobile application where they can log in and obtain information
            about their booking, as well as have access to all information
            regarding that service provided. In addition, the application will
            help customers have access to specific data about the time and date
            of the appointment and save time.
          </h2>
          <p className="card_apply">
            <a
              className="card_link"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/derekbomfimprates/gerGarage"
            >
              See code <i className="fas fa-arrow-right"></i>
            </a>
          </p>
        </div>
        <div className="card card-4">
          <div className="card_icon">
            <i className="fab fa-github"></i>
          </div>
          <p className="card_exit">
            <i className="fas fa-times"></i>
          </p>
          <h2 className="card_content">
            The app will offer a real time news feed and it will allow users the
            possibility to have access to events as:Business news, Financial
            news andSports news.All the headline of news will be displayed in a
            Graphic User Interface and also the time it has being published. The
            news will be available to the user and it is possible to save the
            information and check it later. Also, the users can manager their
            historic and delete and add information. The application will use an
            external API (https://newsapi.org/) to collect the news and show it
            to the users. The application has already set up to display only
            business, financial and sports news.
          </h2>
          <p className="card_apply">
            <a
              className="card_link"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/derekbomfimprates/python"
            >
              See code <i className="fas fa-arrow-right"></i>
            </a>
          </p>
        </div>
        <div className="card card-5">
          <div className="card_icon">
            <i className="fab fa-github"></i>
          </div>
          <p className="card_exit">
            <i className="fas fa-times"></i>
          </p>
          <h2 className="card_content">
            Simple forms in PHP to allow users to contact by email.
          </h2>
          <p className="card_apply">
            <a
              className="card_link"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/derekbomfimprates/formsPHP"
            >
              See code <i className="fas fa-arrow-right"></i>
            </a>
          </p>
        </div>
        <div className="card card-6">
          <div className="card_icon">
            <i className="fab fa-github"></i>
          </div>
          <p className="card_exit">
            <i className="fas fa-times"></i>
          </p>
          <h2 className="card_content">
            Website developed with JavaScript, CSS, NEXT.js, Node.js.
          </h2>
          <p className="card_apply">
            <a
              className="card_link"
              rel="noreferrer"
              href="https://github.com/derekbomfimprates/react-derek-website"
            >
              See code <i className="fas fa-arrow-right"></i>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProjectsInfo;
