import React from "react";
import "../App.css";
import { Button, ButtonProjects } from "./Button";
import "../CSS/Pages/InitialSection.css";

function InitialSection() {
  return (
    <div className="initial-container">
      <img src="/images/img-1.jpg" alt="" />
      <h1>Derek Prates</h1>
      <p>
        I am an experienced and analytical bilingual professional, English and
        Portuguese, with a BSc Degree in Industrial Engineering and Higher
        Diploma in Science in Computing with knowledge in Back-end and Front-end
        of Mobile and Web development. Also, I have experience in managing teams
        and planning solutions to improve the work environment. I possess a
        strong knowledge in Networking, Mobile Development, Web Development,
        Python, Java and JavaScript.
      </p>
      <div className="initial-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          HIRE ME
        </Button>

        <ButtonProjects
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          PROJECTS
        </ButtonProjects>
      </div>
    </div>
  );
}

export default InitialSection;
